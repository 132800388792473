import React from 'react';
import Layout from '../components/Layout';

function About(props) {
    return (
        <Layout>
            <div className="rounded-lg">

                <div className="bg-white shadow-sm p-8" >

                    <h1>About</h1>



                </div>
            </div>
        </Layout>
    );
}

export default About;